import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TokenGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isLogged = this.authService.isLogged();
    const isExpired = this.authService.isExpired();

    if (isLogged && isExpired) {
      this.router.navigate(['/login']);
      localStorage.clear();
      return false;
    }

    if (this.authService.isTimeToRefresh()) {
      return new Promise((resolve) => {
        this.authService.refresh().subscribe({
          next: () => {},
          error: (e) => {
            resolve(false);
          },
          complete: () => {
            resolve(true);
          },
        });
      });
    }

    if (state.url.includes('/login') && isLogged && !isExpired) {
      this.router.navigate(['/']);
      return false;
    }

    if (!state.url.includes('/login') && !isLogged) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }
}
