import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Product } from 'src/app/models/product';

@Component({
  selector: 'app-lifecycle',
  templateUrl: './lifecycle.page.html',
  styleUrls: ['./lifecycle.page.scss'],
})
export class LifecyclePage implements OnInit {
  @ViewChild('dt') table: Table;
  productSelected: Product | null = null;
  products: any[] = [];
  rawMaterials: any[] = [];
  production: any[] = [];
  dialogRawMaterial: boolean = false;
  dialogProduction: boolean = false;
  activeIndex: number = 1;
  productionForm: FormGroup;
  rawMaterialsForm: FormGroup;

  constructor(
    private messageService: MessageService,
    private fb: FormBuilder,
  ) {
    this.initProducts();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.rawMaterialsForm = this.fb.group({
      productSelected: [''],
      quantity: [''],
      unit: ['']
    });

    this.productionForm = this.fb.group({
      productSelected: [''],
      quantity: [''],
      unit: ['']
    });
  }

  initProducts(){
    this.products = [
      {
        id: 1,
        name: 'Iphone 16',
        weigth: 2,
        quantity: 9000,
        sku: '50531',
        image: 'assets/images/image3.jpg'
      },
      {
        id: 2,
        name: 'MacBook 1',
        weigth: 2,
        quantity: 9000,
        sku: '55531',
        image: 'assets/images/image1.jpg'
      },
      {
        id: 3,
        name: 'Bicicleta',
        weigth: 2.5,
        quantity: 6000,
        sku: '10561',
        image: 'assets/images/image2.jpg'
      },
      {
        id: 4,
        name: 'MacBook 2',
        weigth: 0.6,
        quantity: 500,
        sku: '60576',
        image: 'assets/images/image1.jpg'
      },
      {
        id: 5,
        name: 'Bicicleta 2',
        weigth: 1,
        quantity: 700,
        sku: '55731',
        image: 'assets/images/image2.jpg'
      },
    ];
  }

  /**
   * Añadir item a inventario de materia prima o producción
   */
  addItem(type: number){
    if(type === 1){
      this.dialogRawMaterial = true;
    }

    if(type === 2){
      this.dialogProduction = true;
    }
  }

  /**
   * Manejar cierre de modal de materias primas
   */
  closeDialogRawMaterials(){
    this.dialogRawMaterial = false;
    this.productSelected = null;
  }

  /**
   * Manejar cierre de modal de producción
   */
  closeDialogProduction(){
    this.dialogProduction = false;
    this.productSelected = null;
  }

  /**
   * Enviar formulario de materias primas o producción
   * @param type 
   */
  onSubmit(type: number){
    if(type === 1){
      this.rawMaterials.push(this.rawMaterialsForm.value);
      this.rawMaterials = [...this.rawMaterials]; // Clonamos el array para detectar los cambios
      this.messageService.add({
        severity:'success',
        summary: 'OK',
        detail: 'Item añadido al inventario de materia prima'
      });
      this.rawMaterialsForm.reset();
      this.dialogRawMaterial = false;
    }

    if(type === 2){
      this.production.push(this.productionForm.value);
      this.production = [...this.production]; // Clonamos el array para detectar los cambios
      this.messageService.add({
        severity:'success',
        summary: 'OK',
        detail: 'Item añadido al inventario de producción'
      });
      this.productionForm.reset();
      this.dialogProduction = false;
    }
  }
}

